import {
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  useClick,
  useDismiss,
  useFloating,
  useInteractions
} from '@floating-ui/react'
import { Button, Icon } from '@zenchef/ds-react'
import { observer } from 'mobx-react-lite'
import { useContext, useState } from 'react'

import StoresContext from '@/utils/StoresContext'

import LanguageDropdown from './LanguageDropdown'

interface LanguageButtonProps {}

const LanguageButton = observer<LanguageButtonProps>(() => {
  const { appStore } = useContext(StoresContext)
  const { language_availabilities, hideLang, language } = appStore.state

  const [isOpen, setIsOpen] = useState(false)

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: 'bottom-end',
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      flip({
        fallbackPlacements: ['right-start']
      })
    ]
  })
  const click = useClick(context)
  const dismiss = useDismiss(context)

  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss])

  const lang = (language_availabilities.find((l) => l.toLowerCase() === language) ?? 'fr').toLowerCase()

  if (hideLang) {
    return <div style={{ width: '56px', height: '32px' }} />
  }

  return (
    <>
      <Button
        ref={refs.setReference}
        aria-label={lang}
        onClick={() => setIsOpen((isOpen) => !isOpen)}
        hierarchy='brand-reversed-subtler'
        iconOnly
        testId='select-lang'
        {...getReferenceProps()}>
        <Icon name='globe' />
      </Button>

      <FloatingPortal>
        {isOpen && (
          <div ref={refs.setFloating} style={{ zIndex: '104', ...floatingStyles }} {...getFloatingProps()}>
            <LanguageDropdown
              onLanguageChosen={() => {
                setIsOpen(false)
              }}
            />
          </div>
        )}
      </FloatingPortal>
    </>
  )
})

LanguageButton.displayName = 'LanguageButton'

export default LanguageButton
