import { Button, Icon } from '@zenchef/ds-react'
import { HStack } from '@zenchef/styled-system/jsx'
import { token } from '@zenchef/styled-system/tokens'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { PropsWithChildren, ReactNode, useContext, useEffect } from 'react'
import styled, { CSSObject } from 'styled-components'

import ModalHeaderItem from '@/components/Modal/ModalHeaderItem'
import { SUM_PADDING_SDK } from '@/utils/constants'
import { isInIframe } from '@/utils/isInIframe'
import StoresContext from '@/utils/StoresContext'

import LanguageButton from './LanguageButton'
import MadeByZenchef from './MadeByZenchef'
import Modal from './Modal'

const ModalWrapperFullScreenStyles = ({ mini }: { mini?: boolean }) => `
  padding: 0;
  border-radius: 0;
  box-shadow: none;
  background-color: transparent;
  .modal-position {
    box-shadow: none;
    border: 0 !important;
  }
  .modal-position > div {
    min-height: 100vh;
    min-height: 100dvh;
  }
  #modal {
    border-radius: 0;
    width: 100vw;
    min-height: 100vh;
    min-height: 100dvh;
    box-shadow: none;
    border: 0;
  }
  #modal .modal-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    background: white;
  }
  #modal .modal-body > :first-child {
    width: 100%;
  }
  #modal .modal-header {
    background-color: ${token.var('colors.restaurant')};
    color: white;
    ${
      mini &&
      `
        background: transparent;
        border-bottom: 0 !important;
        box-shadow: 0 0 3px rgba(255, 255, 255, 0.5) !important;
        padding-bottom: 0;
      `
    }
    position: ${mini ? 'absolute' : 'fixed'};
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
  }
`

const ModalWrapperSDKStyles = `
  background-color: transparent;
  position: static;
  padding: 0;

  .modal-position {
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
    position: fixed;
    z-index: 0;
    max-width: calc(100% - ${SUM_PADDING_SDK}px);
    max-height: calc(100% - ${SUM_PADDING_SDK}px);
    bottom: ${token('spacing.padding.sdk')};
    right: ${token('spacing.padding.sdk')};
    border: 0 !important;
  }
  #modal .modal-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    background: white;
  }
`

const ModalWrapperNonSDKStyles = `
  .modal-position {
    transition: width 0.2s ease-out;
  }
`

const Wrapper = styled('div')<{
  fullscreen?: boolean
  mini?: boolean
  sdk?: boolean
  bump?: boolean
}>`
  z-index: 2;
  background-color: #2c3037;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;

  position: relative;
  width: 100%;
  min-height: 100vh;
  min-height: 100dvh;

  .modal-position {
    box-shadow: none;
    border: 0 !important;
  }
  ${({ sdk, mini }) =>
    !sdk &&
    `
      @media only screen and (max-width: ${token('breakpoints.sm')}) {
        ${ModalWrapperFullScreenStyles({ mini })}
      }
    `}

  ${({ fullscreen, mini }) =>
    fullscreen
      ? ModalWrapperFullScreenStyles({ mini })
      : `
          padding: 15px;
          .modal-position {
            border-radius: ${token('radii.xl')} !important;
            background-color: transparent !important;
          }
        `}
  ${({ sdk }) => (sdk ? ModalWrapperSDKStyles : ModalWrapperNonSDKStyles)}
      ${({ bump }) =>
    bump
      ? `
          animation: hithere 1s ease;
          transform-origin: center;
          @keyframes hithere {
            30% {
              transform: scale(0.98);
            }
            42%,
            58% {
              transform: translateX(-1px) rotate(-0.5deg) scale(1);
            }
            50% {
              transform: translateX(1px) rotate(0.5deg) scale(1);
            }
            70% {
              transform: rotate(0deg) scale(1);
            }
            100% {
              transform: scale(1);
            }
          }
        `
      : ''}
`

interface ModalWrapperProps extends PropsWithChildren {
  headerRight?: ReactNode
  modalStyle?: CSSObject
  modalBodyStyle?: CSSObject
  modalPositionStyle?: CSSObject
  bump?: boolean
  hideFooter?: boolean
  footer?: ReactNode
}

const ModalWrapper = observer<ModalWrapperProps>(
  ({
    children,
    headerRight,
    modalStyle,
    modalBodyStyle,
    modalPositionStyle,
    bump,
    hideFooter,
    footer
  }): JSX.Element => {
    const { appStore } = useContext(StoresContext)
    const { showCollapsed, isFullscreen } = appStore.state

    const isWhiteLabel = appStore.state.is_white_label

    const state = useLocalObservable(() => ({
      withCloseButton: appStore.state.query && appStore.state.query.withCloseButton !== undefined
    }))

    useEffect(() => {
      if (
        state.withCloseButton &&
        !isInIframe() &&
        !window.opener &&
        !appStore.state.sdk &&
        !appStore.state.showCollapsed
      ) {
        state.withCloseButton = false
      }
    }, [appStore.state, state])

    const onCloseButton = () => {
      if (showCollapsed) {
        appStore.resetIsCollapsed()
      } else {
        if (isInIframe()) {
          window.parent.postMessage('close-widget', '*')
        } else {
          window.close()
        }
      }
    }

    return (
      <div>
        <Wrapper
          id='modal-wrapper'
          fullscreen={isFullscreen}
          mini={appStore.state.mini}
          sdk={appStore.state.sdk}
          bump={bump}>
          <Modal
            id='modal'
            fullscreen={isFullscreen}
            modalBodyId='main-modal-body'
            modalHeaderId='main-modal-header'
            modalFooterId='main-modal-footer'
            fancyScrollbar
            modalStyle={modalStyle}
            modalBodyStyle={modalBodyStyle}
            modalPositionProps={{
              className: 'modal-position'
            }}
            modalPositionStyle={modalPositionStyle}
            sdk={appStore.state.sdk}
            className='modal-body'
            header={<ModalHeaderItem />}
            headerRight={
              <HStack gap='gap.0' minW='{sizes.3xl}' justify='end'>
                <LanguageButton />
                {headerRight ??
                  (state.withCloseButton ? (
                    <Button onClick={onCloseButton} testId='close-modal' hierarchy='brand-reversed-subtler' iconOnly>
                      <Icon name='x' />
                    </Button>
                  ) : null)}
              </HStack>
            }
            footer={hideFooter ? null : footer ?? (isWhiteLabel ? null : <MadeByZenchef />)}>
            {children}
          </Modal>
        </Wrapper>
      </div>
    )
  }
)

export default ModalWrapper
